import type { FallbackProps } from 'react-error-boundary';

import { Button } from '@quick/components';

import useCompatibility from '@/layers/core/deal/services/useCompatibility';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { isValidBrowser, browser } = useCompatibility();
  return (
    <div
      role="alert"
      className="row-span-full flex h-full w-screen flex-col items-center justify-center gap-5"
    >
      <div className="mx-4 flex w-full max-w-xl flex-col gap-4 rounded border-2 border-red-400 p-4">
        <p className="font-bold text-red-600 dark:text-red-400">
          Algo salió mal | Something went wrong:
        </p>
        <pre
          className="scroll-thin max-h-32 overflow-y-auto overflow-x-hidden whitespace-pre-wrap
            rounded bg-gray-100 p-2 text-gray-500"
        >
          <span className="font-semibold">{`${error.name}: ${error.message}`}</span>
          {`\n\n${error.stack}`}
        </pre>
        {!isValidBrowser && (
          <>
            <p className="font-semibold text-red-600 dark:text-red-400">
              {browser.name} {browser.version} detected!
            </p>
            <p className="text-sm text-red-600 dark:text-red-400">
              Asegurate de que tu navegador está actualizado. Esto garantiza que
              cuentas con las características necesarias para el correcto
              funcionamiento. Además es importante porque las actualizaciones
              del navegador incluyen mejoras a vulnerabilidades de seguridad.
            </p>
            <p className="text-sm text-red-600 dark:text-red-400">
              Make sure your browser is updated. This guarantees you have the
              required features for the correct operation of the application. It
              also is important because browser updates includes patches for
              security vulnerabilities.
            </p>
          </>
        )}
      </div>
      <div className="flex flex-col flex-wrap gap-5 sm:flex-row">
        <button
          onClick={resetErrorBoundary}
          className="bg-brand hover:bg-brand-dark rounded p-2 font-bold text-gray-800"
        >
          Reintentar | Try again
        </button>
        <Button
          variant="reject"
          onClick={() => {
            window.location.href = window.location.origin;
          }}
        >
          Volver al inicio | Go Home
        </Button>
      </div>
    </div>
  );
}

export { ErrorFallback };
