'use client';
import type { ReactNode } from 'react';

import axios, { type GenericFormData } from 'axios';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components/core/ErrorFallback';
import { envs } from '@/layers/core/data/const';
import type { IDiscordHookReq } from '@/layers/core/deal/reqResTypes/discord';
import { useUser } from '@/layers/core/deal/services/useUser';

export function Boundary({ children }: { children: ReactNode }) {
  const { id } = useUser();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        sessionStorage.removeItem('quick-demand');
        const location = window.location.href;
        window.location.href = location;
      }}
      onError={(error, info) => {
        if (error?.name === 'ChunkLoadError') return location.reload();
        if (!envs.logWH) return;

        const trimmed = info.componentStack?.trim?.() ?? 'Missing Stack';
        let callstack: BlobPart[];

        try {
          // Some browsers do not support Uint8Array
          const utf8Encode = new TextEncoder();
          callstack = [
            utf8Encode
              .encode(trimmed)
              // Max file size = 8MB
              .slice(0, 1024 * 1024 * 8),
          ];
        } catch {
          callstack = [
            trimmed
              // UTF-8 char can be 1 - 4 bytes
              .slice(0, (8 * 1024 * 1024) / 4),
          ];
        }
        const callstackFile = new File(callstack, 'callstack.txt');

        const message = axios.toFormData({
          payload_json: JSON.stringify({
            content: `:boom: An error occured in **pwa-core**...
Below you can find information of what happened.

**${error.name}:** ${error.message}`,
            embeds: [
              {
                title: `Description`,
                description: 'Where the error happened, and who triggered it',
                fields: [
                  {
                    name: 'URL',
                    value: window?.location.toString(),
                    inline: true,
                  },
                  { name: 'User ID', value: `${id}`, inline: true },
                  {
                    name: 'User Agent',
                    value: window?.navigator?.userAgent ?? 'Not Accesible',
                  },
                ],
                color: 16711680,
                timestamp: new Date().toISOString(),
              },
            ],
          } as IDiscordHookReq),
          file: [callstackFile],
        });

        axios.post<unknown, unknown, GenericFormData>(envs.logWH, message);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
